/* --- Modified radio & checkboxes */
/*--- Default---*/
input[type="radio"] {
  font-size: 20px;
  border-width: 0;
  appearance: none;
  cursor: pointer;
  background-image: url("./assets/images/radio-unchecked.svg");

  &:checked {
    background-image: url("./assets/images/radio-checked.svg");
    appearance: none;
  }
  &:disabled {
    opacity: 1;
    cursor: not-allowed !important;
    pointer-events: all !important;
    ~ label {
      opacity: 1;
    }
  }

  &:focus {
    border-width: 0;
    box-shadow: none;
  }
}

input[type="checkbox"] {
  font-size: 20px;
  border-width: 0;
  appearance: none;
  cursor: pointer;
  background-image: url("./assets/images/checkbox-unchecked.svg");

  &:checked {
    background-image: url("./assets/images/checkbox-checked.svg");
    appearance: none;
  }

  &:focus {
    border-width: 0;
    box-shadow: none;
  }
  &:disabled {
    opacity: 1;
    cursor: not-allowed !important;
    pointer-events: all !important;
    ~ label {
      opacity: 1;
    }
  }

  &.form-check-input {
    &:checked {
      background-color: colors.$collapsed-accordian-color;
      border-color: colors.$collapsed-accordian-color;
    }
  }

  // &.custom-red-checkbox {
  //   // &:checked {
  //   //   background-color: colors.$collapsed-accordian-color;
  //   //   border-color: colors.$collapsed-accordian-color;
  //   // }
  // }
}
/*---Bootstrap default---*/
.form-check {
  padding-left: 0px !important;
  label {
    margin-left: 8px;
    line-height: 30px;
  }
  input[type="radio"] {
    font-size: 20px;
    border-width: 0;
    background-image: url("./assets/images/radio-unchecked.svg");

    &:checked {
      background-image: url("./assets/images/radio-checked.svg");
    }

    &:focus {
      border-width: 0;
      box-shadow: none;
    }
    &.form-check-input {
      margin-left: 0;
    }
    &:disabled {
      opacity: 1;
      cursor: not-allowed !important;
      pointer-events: all !important;
      ~ label {
        opacity: 1;
      }
    }
  }

  input[type="checkbox"] {
    font-size: 20px;
    border-width: 0;
    background-image: url("./assets/images/checkbox-unchecked.svg");

    &:checked {
      background-image: url("./assets/images/checkbox-checked.svg");
    }

    &:focus {
      border-width: 0;
      box-shadow: none;
    }

    &.form-check-input {
      margin-left: 0;
      &:checked {
        background-color: colors.$collapsed-accordian-color;
        border-color: colors.$collapsed-accordian-color;
      }
      &:disabled {
        opacity: 1;
        cursor: not-allowed !important;
        pointer-events: all !important;
        ~ label {
          opacity: 1;
        }
      }
    }
    &.custom-red-checkbox {
      &:checked {
        background-image: url("./assets/images/red-checkbox.svg");
        appearance: none;
        background-color: colors.$danger-checkbox-color;
        border-color: colors.$danger-checkbox-color;
      }
    }
  }
}
