.lp-error-app {
  max-width: 360px;
  margin: 0px auto 40px;
}

.lp-error-app .uk-article-title {
  margin-top: 20px;
}

.lp-error-icon {
  margin: 20px auto;
  max-width: 340px;
  max-height: 140px;
  height: auto;
}

.error-container {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  .status-code {
    font-size: calc(5rem + 2vw);
    color: #cc1e00;
  }
}
.text-red {
  color: #cc1e00 ;
}