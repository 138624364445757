@use "src/mixins" as mixins;
@use "src/colors" as colors;
@import "./input";
@import "./buttons";
@import "./theme.scss";

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  color: $body-color;
  font-family: $font-family-base;
}

.btn:focus {
  box-shadow: none !important;
}

*,
*:focus,
*:hover {
  outline: none;
}

.container,
[class^="col-"],
.row,
.container-fluid {
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}

.screen {
  padding: 10px;
  border: none;
}

.nav-tabs {
  width: fit-content;
  border: none;

  .nav-link {
    border: 1px solid #dddbda !important;
    border-radius: 3px;
    padding: 0.25rem 1rem;
    background-color: white;
    color: #5f6076;
    height: 56px;
    font-weight: 700;
    letter-spacing: 0.0035em;
    line-height: 32px;
  }

  .nav-link.active {
    background-color: #e9ebf8;
    color: #3356d7;
    border: none !important;
  }

  .nav-link.hover {
    border: none !important;
  }
}

.horizontal-spacing {
  visibility: hidden;
  border: 1px solid #d8dde6;
  margin-top: 32px;
  margin: 10px 0 !important;
}

.infobox-value {
  @include mixins.fonts(12px, 400, #111217);
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
  line-height: 18px;
  letter-spacing: 0.0035em;
  text-transform: capitalize;
}

.divider {
  border-top: 1px solid #cacbde;
  margin: 16px 0;
}

// .tab-pane {
//   padding: 1rem 0;
// }

.main-screen {
  background-color: #fff;

  .main-page-container {
    background: #ffffff;
    /* Light/Base-50 */

    border: 1px solid #e6e6f5;
    box-shadow: 0px 4px 10px rgba(17, 18, 23, 0.05);
    border-radius: 16px;
    padding: 20px;

    .main-header {
      @include mixins.fonts(24px, 600, #080707);
    }
  }
}

.margin-spacing {
  margin-bottom: 30px;
}

.heading-details {
  @include mixins.headings();
  margin: 25px 0;
}

.table-info {
  margin-top: 28px;
}

.table {
  th {
    vertical-align: middle;

    &.no-bg {
      span {
        @include mixins.fonts(12px, 500, colors.$acc-body-sub-headings-color);
        text-transform: uppercase;
        letter-spacing: 0.0035em;
      }
    }

    &.tb-head {
      @include mixins.fonts(12px, 500, colors.$acc-body-sub-headings-color);
      background: #e6e6f5;
      text-transform: uppercase;
      border-width: 0px 1px 1px 0px;
      border-style: solid;
      border-color: #cacbde;
      text-align: center;
      width: 10%;
      letter-spacing: 0.0035em;
    }

    &.tb-head-hor {
      background: #7b7c97;
      color: white;
      text-align: center;
    }
  }

  td {
    @include mixins.fonts(16px, 400, colors.$acc-body-sub-headings-color);
    background: #ffffff;
    text-align: right;
    border-width: 0px 1px 1px 0px;
    border-style: solid;
    border-color: #e6e6f5;
    letter-spacing: 0.0035em;
    vertical-align: middle;
  }
}

.accordion-item {
  border: none !important;
}

.lp-form-field {
  position: relative;
}

.form-controls {
  position: relative;

  label {
    top: 15px;
    left: 16px;
    position: absolute;
    transition: 0.2s;
    @include mixins.fonts(16px, 300, colors.$acc-body-sub-headings-color);
    pointer-events: none;
  }
}

.inputbox {
  width: 100%;
  border: 1px solid #cacbde;
  border-radius: 8px;

  padding: 12px 16px;
}

input:focus {
  border: 1px solid #5f6076;
}


.lp-error {
  position: relative;
}

.lp-error:not([hidden]) {
  display: block;
}

.error-hidden {
  display: none;
}

.lp-error p {
  @include mixins.fonts(11px, 500, red);
  letter-spacing: 0.25px;
  line-height: 1.5;
  padding: 0 8px;
  margin: 0;
}

.form-danger,
.form-danger:focus {
  border-color: red !important;
}

.ifOHjV {
  position: relative;
  width: 100%;
  display: block !important;
  overflow: auto;
}

.rdt_Table .rdt_TableHeadRow .rdt_TableCol {
  justify-content: center;
  color: white !important;
}

.rdt_Table .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable div {
  color: white;
}

.ml-1 {
  margin-left: 10px;
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
  background-color: #1c2a9a;
  color: #fff !important;

  img {
    filter: brightness(3) !important;
  }
}

.btn-outline-primary:disabled {
  color: #5f6076;
}

// back button navatigation //
.back-navigation-container {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  transition: 0.3s all ease;
  color: #5f6076;
  cursor: pointer;
  font-size: 20px;

  &:hover {
    background: #e6e6f5;
    color: #3356d7;
  }

  &:focus,
  &:active {
    background: #3356d7;
    color: white;
  }
}

//form-control new UI
.form-floating {
  input:focus {
    box-shadow: none;
    border-color: #cacbde;
  }

  select:focus {
    box-shadow: none;
    border-color: #cacbde;
  }

  textarea:focus {
    box-shadow: none;
    border-color: #cacbde;
  }

  svg {
    color: #3356d7 !important;
  }

  label {
    font-size: 16px;
    font-weight: 300;
    color: #5f6076;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}

.loan-history-last-value [data-tag="allowRowEvents"] {
  white-space: normal !important;
}

.height-100 {
  height: 100%;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-select~label {
  font-weight: 400;
  font-size: 14px;
  opacity: 1;
}

.modal-backdrop.show {
  opacity: 0.4;
  background-color: #2a46b9;
}

.border-right {
  border-right: 1px solid #e6e6f5;
}

.custom-tooltip {
  position:relative; /* making the .tooltip span a container for the tooltip text */

  // floating tooltip
  &:before {
    content: attr(data-title); /* here's the magic */
    position:absolute;
    
    /* vertically center and move top */
    top:0%;
    left: 50%;
    transform:translateX(calc(-50%)) translateY(calc(-100% - 15px));
    
    
    /* move to right */
    
    /* basic styles */
    width:auto;
    padding:10px;
    border-radius:10px;
    background:#000;
    color: #fff;
    text-align:center;
  
    display:none; /* hide by default */
  }

  // tooltip arrow
  &:after {
    content: "";
    position:absolute;
    
    /* position tooltip correctly */
    left:50%;
    
    /* vertically center and move top just below the tooltip */
    /* 5px is used because of width of arrow */
    top:0%;
    transform:translateX(calc(-50%)) translateY(calc(-100% + 5px));
   
    /* the arrow */
    border:10px solid #000;
    border-color: black transparent transparent transparent;
    
    display:none;
  }
  
  // showing tooltip parts on hover action.
  &:hover:before,
  &:hover:after {
    display:block;
  }
}


.inputbox-label {
  @include mixins.fonts(16px, 500, #111217);
}

.input-masked-container {
  height: 100%;
  border: 1px solid #cacbde;
  border-radius: 8px;
  padding: 12px 16px;
  // text-transform: capitalize;
  resize: none;
  border-radius: 8px !important;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}