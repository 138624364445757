/*--Buttons ---*/
.btn-editing-mode {
  @include mixins.fonts(18px, 700, colors.$collapsed-accordian-color);
  border: 1px dashed colors.$collapsed-accordian-color;
  border-radius: 16px;
  transition: all ease-in-out 0.3s;
  padding: 16px;
  height: 64px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  span {
    @include mixins.fonts(12px, 300, colors.$collapsed-accordian-color);
    text-transform: uppercase;
    margin-left: 10px;
    margin-top: 3px;
  }
}

.btn-outline-primary {
  &.btn-cancel {
    padding: 12px 16px;
    background: #ffffff;
    border: 1px solid #e6e6f5;
    border-radius: 16px;
    @include mixins.fonts(16px, 300, colors.$acc-body-sub-headings-color);
    line-height: 32px;
    letter-spacing: 0.0035em;
    text-transform: capitalize;
    height: 56px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    img,
    svg {
      margin-left: 16px;
    }
  }

  &.btn-edit {
    @extend .btn-cancel;
    color: colors.$acc-body-sub-headings-color;
  }

  &.btn-danger {
    @extend .btn-cancel;
    border-color: colors.$danger-checkbox-color;
    background-color: colors.$danger-checkbox-color;
    color: white;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
  }

  &.btn-save {
    @extend .btn-cancel;
    background: #e9ebf8;
    @include mixins.fonts(16px, 700, colors.$collapsed-accordian-color);
  }

  &.btn-saved {
    @extend .btn-cancel;
    background: #57a551;
    color: #fff;
    &:hover {
      background: #57a551;
    }
  }

  &.btn-info {
    @extend .btn-cancel;
    background: #3356D7;
    @include mixins.fonts(16px, 700, colors.$btn-bg-color);
    img {
      filter: brightness(3);
    }
    &:hover {
      background: #1c2a9a;
    }
  }

  &.btn-loading {
    @extend .btn-saved;
    background: #3356d7;
    opacity: 0.4;
    pointer-events: none;
    &:hover {
      background: #3356d7;
    }
    .spinner-border {
      margin-left: 16px;
      &.text-primary {
        color: #fff !important;
      }
    }
    &:disabled{
      .spinner-border{
        &.text-primary{
          color: #3356d7 !important;
        }
      }
    }
  }
}
