@use "colors" as colors;

@mixin btn($width) {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
  width: $width;
  background: colors.$btn-bg-color;
  border: 1px solid colors.$btn-border-color;
  border-radius: 16px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  color: colors.$btn-text-color;
  justify-content: center;
  height:50px;
}

@mixin btn-hover($width) {
  background: colors.$btn-hover-bg-color;
  color: colors.$btn-hover-text-color;
  border: 1px solid colors.$btn-hover-border-color;
}

@mixin headings(){
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 125%;
  letter-spacing: 0.0035em;
  text-transform: capitalize;
  color: colors.$acc-body-sub-headings-color;
  padding: 0;
}

@mixin fonts($size,$weight,$color) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
}