//button color on hover

$btn-bg-color: #fff;
$btn-text-color: #5f6076;
$btn-border-color: #cacbde;
$btn-hover-text-color: #fff;
$btn-hover-border-color: #0074d9;
$btn-hover-bg-color: #1c2a9a;
$toggle-btn-hover-color: #5F6076;
$collapsed-accordian-color: #3356d7;
//accordion sub-heading color
$acc-body-sub-headings-color: #5f6076;
$btn-label-color: #111217;
$danger-checkbox-color: #cc1e00;
$link-color: #3356d7;
$link-hover-color: #1c2a9a;
