.auth {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.auth-loader {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}