@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,400;0,500;0,600;1,100;1,200;1,400;1,600&family=Rubik:wght@300;400&display=swap");
$font-family-base: "Libre Franklin", sans-serif;
$body-color: black;
$body-bg: #fff;

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

$theme-colors: (
  "primary": #0074d9,
  "danger": #ff4136,
);

// Create your own map
$custom-colors: (
  "nav-bg": #e7e8e9,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

@import "../node_modules/bootstrap/scss/bootstrap";
